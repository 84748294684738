import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Footer from 'components/Footer';
import { Button } from 'components/1TalkDesign/Buttons';
import { PAGE_NAME as FIRST_PAGE } from 'components/appointments/SelectDatePage';
import { t } from 'src/i18n/config';

const FOOTER_HEIGHT = 58;

const MessageContainer = styled.div`
  display: flex;
  height: calc(100vh - ${FOOTER_HEIGHT}px);
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Img = styled.i`
  font-size: 64px;
  color: ${(props) => props.theme.colors.error};
  line-height: initial;
`;

const Title = styled.div`
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.07px;
  text-align: center;
`;
const Description = styled.div`
  margin: 16px 48px 0 48px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: center;
`;

const FooterContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
`;

const ReAppointmentButton = styled(Button)`
  width: 100%;
  margin: 8px 0;
`;

const convertMessages = {
  不接受的時間2: t('reservation.error.cannotReserveOneHourAhead'),
};
function FailedPage({ message, onChangeStep }) {
  const handleClick = () => {
    onChangeStep(FIRST_PAGE);
  };

  return (
    <React.Fragment>
      <Modal>
        <MessageContainer>
          <Img className="ri-close-circle-line"></Img>
          <Title>{t('appointmentFailed')}</Title>
          <Description>
            {convertMessages[message] ? convertMessages[message] : message}
          </Description>
        </MessageContainer>
        <Footer>
          <FooterContainer>
            <ReAppointmentButton onClick={handleClick}>
              {t('reAppointment')}
            </ReAppointmentButton>
          </FooterContainer>
        </Footer>
      </Modal>
    </React.Fragment>
  );
}

FailedPage.propTypes = {
  message: PropTypes.string,
  contact: PropTypes.string,
  onChangeStep: PropTypes.func,
};

export default FailedPage;
export const PAGE_NAME = 'FailedPage';
export const prepareProps = async (state) => {
  return state;
};
